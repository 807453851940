import React, { useEffect } from "react";
import "./Services.css";
import resim1 from "../assets/images/gallery/thumbnails/9.webp";
import resim2 from "../assets/images/gallery/thumbnails/26.webp";
import resim3 from "../assets/images/gallery/thumbnails/23.webp";
import resim4 from "../assets/images/gallery/thumbnails/38.webp";
import resim5 from "../assets/images/gallery/thumbnails/45.webp";
import resim6 from "../assets/images/gallery/thumbnails/54.webp";

const Services = () => {
  const rows = [
    {
      image: resim1,
      text: `MAYA Sapanca Bungalov, doğanın kalbinde konfor ve lüksü bir araya getiriyor. 
Burada, yemyeşil ormanların arasında, huzur dolu bir
ortamda dinlenme fırsatı bulabilirsiniz. Isıtmalı havuz, jakuzi ve ateş çukuru gibi özel imkanları ile dört
mevsim tatilin keyfini çıkarmanız için mükemmel bir seçim.`,
    },
    {
      image: resim2,
      text: `MAYA Sapanca Bungalov’nın en dikkat çekici özelliklerinden biri
ısıtmalı havuzlarıdır. Bu sayede, soğuk kış günlerinde bile sıcak
suyun tadını çıkarabilirsiniz. Aynı zamanda bungalovlarda bulunan
jakuziler, rahatlamanızı ve stresten arınmanızı sağlar. Günün
yorgunluğunu atmak için jakuzide vakit geçirmek, tatilinizi daha da
keyifli hale getirecektir.`,
    },
    //     {
    //       image: resim3,
    //       text: `Doğanın içinde ateş başında oturmak, sevdiklerinizle sıcak sohbetler
    // etmek ve yıldızları seyretmek… MAYA Sapanca Bungalov, bu eşsiz
    // deneyimi yaşamanız için her bungalovda ateş çukuru imkanı sunuyor.
    // Ateş çukurunun etrafında toplanıp marshmallow kızartabilir,
    // unutulmaz anılar biriktirebilirsiniz.`,
    //     },
    {
      image: resim3,
      text: `MAYA Sapanca Bungalov, doğa ile iç içe bir tatil sunar. Ormanların
arasında yer alan bungalovlar, kuş cıvıltıları ve temiz hava
eşliğinde dinlenme fırsatı sunar. Doğa yürüyüşleri yapabilir,
bisiklet turlarına katılabilir veya sadece bungalovunuzun
verandasında oturup manzaranın tadını çıkarabilirsiniz. Burada
geçireceğiniz her an, şehir hayatının stresinden uzaklaşmanızı
sağlayacaktır.`,
    },
    {
      image: resim4,
      text: `MAYA Sapanca Bungalov, misafirlerine en yüksek konforu sunmak için
özenle tasarlanmıştır. Şömineli oturma odaları, tam donanımlı
mutfaklar ve konforlu yataklar, tatilinizi unutulmaz kılmak için
düşünülmüş detaylardan sadece birkaçıdır. Ayrıca, her bungalovun
kendine ait geniş terası ve özel bahçesi bulunur. Barbekü alanları
ile kendi yemeklerinizi hazırlayabilir, açık havada yemeğin keyfini
çıkarabilirsiniz.`,
    },
    {
      image: resim5,
      text: `MAYA Sapanca Bungalov, huzur ve dinlenme arayanlar için ideal bir
tatil rotasıdır. Isıtmalı havuz, jakuzi ve ateş çukuru gibi
olanaklarla dolu bu bungalovlarda, doğanın içinde konforlu ve lüks
bir tatil deneyimi sizi bekliyor. Şehrin gürültüsünden uzaklaşmak,
doğanın tadını çıkarmak ve sevdiklerinizle unutulmaz anılar
biriktirmek için MAYA Sapanca Bungalov’yı tercih edin.`,
    },
    {
      image: resim6,
      text: `Sapanca Gölü etrafında yürüyüş yapabilir, bisiklet turlarına
katılabilir veya gölde sandal sefası yapabilirsiniz. Ayrıca,
Maşukiye Şelaleleri, Kartepe Kayak Merkezi ve Serdivan Gölpark gibi
yakın çevredeki doğal güzellikleri keşfedebilirsiniz. MAYA
Bungalov Sapanca'da konaklarken, bu eşsiz bölgede yapılacak pek çok
aktiviteyle tatilinizi daha da zenginleştirebilirsiniz.`,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const rows = document.querySelectorAll(".hizmetler-row");
      rows.forEach((row) => {
        const rect = row.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          row.classList.add("animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section id="hizmetler" className="hizmetler-section">
      <h1 className="section-header">Hizmetler</h1>
      <div className="container">
        {rows.map((row, index) => (
          <div
            className={`hizmetler-row ${
              index % 2 === 0 ? "text-left" : "text-right"
            }`}
            key={index}
          >
            <div className="hizmetler-image">
              <img src={row.image} alt={`About ${index + 1}`} />
            </div>
            <div className="hizmetler-text">
              <p>{row.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
