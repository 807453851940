import React, { useState, useEffect, useRef } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./WhatsAppButton.css";

const WhatsAppButton = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [, setSelectedMessage] = useState("");
  const [userText, setUserText] = useState("");
  const optionsRef = useRef(null);
  const buttonRef = useRef(null);
  const messageOptions = [
    "Rezervasyon yaptırmak istiyorum, yardımcı olur musunuz?",
    "Görüntülü arama ile tesisi görmem mümkün mü?",
    "Bu haftasonu müsait yeriniz var mı?",
    "Rezervasyon için kapora ödemem gerekiyor mu?",
    "Kredi kartıyla rezervasyon yapılabiliyor mu?",
    "Banka havalesi ile rezervasyon yapılabiliyor mu?",
    "Konum gönderebilir misiniz?",
    // "Bungalov konusunda çok fazla dolandırıcı var. Size nasıl güvenebilirim?",
  ];
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  // Handle message selection
  const handleMessageClick = (message, userInput = false) => {
    setSelectedMessage(message);
    if (message === "") return;
    setIsDropdownVisible(false);
    // if (message == "Mesajınızı yazın...") message = "";
    // Redirect to WhatsApp with the selected message
    const phoneNumber = "905051606292"; // Replace with your number
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      (!userInput ? "Merhaba, " : "") + message
    )}`;
    window.open(whatsappUrl, "_blank");
    setUserText("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
          setIsDropdownVisible(false);
        }
      }
    };

    // Add event listener when options are visible
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component unmounts or options are hidden
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible]);

  return (
    <div className="whatsapp-container">
      <button
        ref={buttonRef}
        aria-label="Whatsapp"
        className="whatsapp-button"
        onClick={toggleDropdown}
      >
        <i className="fab fa-whatsapp"></i>
      </button>
      {/* Dropdown with message options */}
      {isDropdownVisible && (
        <div ref={optionsRef} className="whatsapp-dropdown">
          <div className="whatsapp-header">
            <div className="whatsapp-user"></div>
            <strong>MAYA Sapanca Bungalov</strong>
          </div>
          <div className="whatsapp-options-container">
            <div className="whatsapp-option-self">
              Aşağıdaki mesajlardan birini tıklayarak veya istediğiniz başka bir
              şey yazarak bize gönderebilirsiniz.
            </div>
            {messageOptions.map((message, index) => (
              <div
                key={index}
                className="whatsapp-option"
                onClick={() => handleMessageClick(message)}
              >
                {message}
              </div>
            ))}
          </div>
          <div className="whatsapp-input-container">
            <input
              type="text"
              className="whatsapp-input"
              placeholder="Mesajınızı yazın..."
              value={userText}
              onChange={(e) => setUserText(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault(); // Prevents default behavior, if necessary

                  handleMessageClick(userText, true);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsAppButton;
