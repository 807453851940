import React, { useEffect, useRef } from "react";
import "./Bungalows.css";
import FacilityList from "./Facilities";
import bungalov from "../assets/images/bungalows/bungalov.webp";
import bahce from "../assets/images/bungalows/bahce.webp";
import havuz from "../assets/images/bungalows/havuz.webp";
import mahremiyet from "../assets/images/bungalows/mahremiyet.webp";
import jakuzi from "../assets/images/bungalows/jakuzi.webp";
import kahvalti from "../assets/images/bungalows/kahvalti.webp";

const bungalows = [
  {
    image: bungalov,
    title: "Bungalov",
    description:
      "Bungalovlar, 70 m\u00B2 kullanım alanına sahip 2 oda + salon şeklinde dizayn edilmiş olup 4 yetişkin ve 2 çocuğun konaklaması için yeterli genişliktedir. Her ihtiyacınızı karşılayabilecek bir mutfağa sahiptir. Klimalı ve yerden ısıtmalı bungalovlarımız 4 mevsim konaklamaya elverişlidir.",
  },
  {
    image: bahce,
    title: "Geniş bahçe",
    description:
      "Her bungalovun kendine ait yaklaşık 80 m\u00B2 genişliğinde özel bir bahçesi bulunmaktadır. Bu alan misafirlerimize doğayla iç içe, huzurlu bir ortam sunarken, barbekü yapma ve dış mekan aktiviteleri için de uygundur.",
  },
  {
    image: havuz,
    title: "Yüzme Havuzu",
    description:
      "Havuzlarımız 3x6 metre boyutlarında ve ısıtmalıdır. Düzenli olarak bakım ve temizliği yapılmakta, klor ve ph seviyesi kontrol edilmektedir.",
  },
  {
    image: mahremiyet,
    title: "Mahremiyet",
    description:
      "Misafirlerimizin mahremiyetini ve diğer bungalovlarda yapılan gürültülerden rahatsız olmamalarını sağlamak maksadıyla bungalovlar birbirinden uzak konumlandırılmıştır. ",
  },
  {
    image: jakuzi,
    title: "Jakuzi",
    description:
      "Bungalovlarımızda bulunan jakuziler, günün yorgunluğunu atmanız için idealdir. Jakuzilerin bakım ve temizliği hijyen kurallarına uygun olarak düzenli bir şekilde yapılmaktadır.",
  },
  {
    image: kahvalti,
    title: "Ücretsiz Kahvaltı *",
    description:
      "Konaklamanıza dahil olan kahvaltımız her sabah odanıza servis edilmektedir. Taze yerel ürünler ve ev yapımı lezzetlerle donatılan menümüz, güne güzel bir başlangıç yapmanızı sağlar.",
  },
  // Add more bungalow objects as needed
];

const Bungalows = () => {
  const bungalowRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    bungalowRefs.current.forEach((bungalow) => {
      observer.observe(bungalow);
    });

    return () => {
      bungalowRefs.current.forEach((bungalow) => {
        observer.unobserve(bungalow);
      });
    };
  }, []);

  return (
    <section id="bungalovlar" className="bungalows-section">
      <h1 className="section-header">Bungalovlar</h1>
      <div className="bungalows-container">
        {bungalows.map((bungalow, index) => (
          <div
            className="bungalow-card"
            key={index}
            ref={(el) => (bungalowRefs.current[index] = el)}
          >
            <img
              src={bungalow.image}
              alt={bungalow.title}
              className="bungalow-image"
              height={250}
            />
            <div className="bungalow-info">
              <h1 className="bungalow-title">{bungalow.title}</h1>
              <p className="bungalow-description">{bungalow.description}</p>
            </div>
          </div>
        ))}
        <FacilityList />
        <small>
          * Ücretsiz kahvaltı iki kişiliktir. İlave kahvaltı talepleri
          resepsiyona bildirilmesi durumunda ücreti karşılığında servis
          edilmektedir.
        </small>
      </div>
    </section>
  );
};

export default Bungalows;
