import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Gallery.css";
import resim1 from "../assets/images/gallery/1.webp";
import resim2 from "../assets/images/gallery/2.webp";
import resim3 from "../assets/images/gallery/3.webp";
import resim4 from "../assets/images/gallery/4.webp";
import resim5 from "../assets/images/gallery/5.webp";
import resim6 from "../assets/images/gallery/6.webp";
import resim7 from "../assets/images/gallery/7.webp";
import resim8 from "../assets/images/gallery/8.webp";
import resim9 from "../assets/images/gallery/9.webp";
import resim10 from "../assets/images/gallery/10.webp";
import resim11 from "../assets/images/gallery/11.webp";
import resim12 from "../assets/images/gallery/12.webp";
import resim13 from "../assets/images/gallery/13.webp";
import resim14 from "../assets/images/gallery/14.webp";
import resim15 from "../assets/images/gallery/15.webp";
import resim16 from "../assets/images/gallery/16.webp";
import resim17 from "../assets/images/gallery/17.webp";
import resim18 from "../assets/images/gallery/18.webp";
import resim19 from "../assets/images/gallery/19.webp";
import resim20 from "../assets/images/gallery/20.webp";
import resim21 from "../assets/images/gallery/21.webp";
import resim22 from "../assets/images/gallery/22.webp";
import resim23 from "../assets/images/gallery/23.webp";
import resim24 from "../assets/images/gallery/24.webp";
import resim25 from "../assets/images/gallery/25.webp";
import resim26 from "../assets/images/gallery/26.webp";
import resim27 from "../assets/images/gallery/27.webp";
import resim28 from "../assets/images/gallery/28.webp";
import resim29 from "../assets/images/gallery/29.webp";
import resim30 from "../assets/images/gallery/30.webp";
import resim31 from "../assets/images/gallery/31.webp";
import resim32 from "../assets/images/gallery/32.webp";
import resim33 from "../assets/images/gallery/33.webp";
import resim34 from "../assets/images/gallery/34.webp";
import resim35 from "../assets/images/gallery/35.webp";
import resim36 from "../assets/images/gallery/36.webp";
import resim37 from "../assets/images/gallery/37.webp";
import resim38 from "../assets/images/gallery/38.webp";
import resim39 from "../assets/images/gallery/39.webp";
import resim40 from "../assets/images/gallery/40.webp";
import resim41 from "../assets/images/gallery/41.webp";
import resim42 from "../assets/images/gallery/42.webp";
import resim43 from "../assets/images/gallery/43.webp";
import resim44 from "../assets/images/gallery/44.webp";
import resim45 from "../assets/images/gallery/45.webp";
import resim46 from "../assets/images/gallery/46.webp";
import resim47 from "../assets/images/gallery/47.webp";
import resim48 from "../assets/images/gallery/48.webp";
import resim49 from "../assets/images/gallery/49.webp";
import resim50 from "../assets/images/gallery/50.webp";
import resim51 from "../assets/images/gallery/51.webp";
import resim52 from "../assets/images/gallery/52.webp";
import resim53 from "../assets/images/gallery/53.webp";
import resim54 from "../assets/images/gallery/54.webp";
import resim55 from "../assets/images/gallery/55.webp";
import resim56 from "../assets/images/gallery/56.webp";
import resim57 from "../assets/images/gallery/57.webp";
import resim58 from "../assets/images/gallery/58.webp";
import resim59 from "../assets/images/gallery/59.webp";
import resim60 from "../assets/images/gallery/60.webp";

import thumbnail1 from "../assets/images/gallery/thumbnails/1.webp";
import thumbnail2 from "../assets/images/gallery/thumbnails/2.webp";
import thumbnail3 from "../assets/images/gallery/thumbnails/3.webp";
import thumbnail4 from "../assets/images/gallery/thumbnails/4.webp";
import thumbnail5 from "../assets/images/gallery/thumbnails/5.webp";
import thumbnail6 from "../assets/images/gallery/thumbnails/6.webp";
import thumbnail7 from "../assets/images/gallery/thumbnails/7.webp";
import thumbnail8 from "../assets/images/gallery/thumbnails/8.webp";
import thumbnail9 from "../assets/images/gallery/thumbnails/9.webp";
import thumbnail10 from "../assets/images/gallery/thumbnails/10.webp";
import thumbnail11 from "../assets/images/gallery/thumbnails/11.webp";
import thumbnail12 from "../assets/images/gallery/thumbnails/12.webp";
import thumbnail13 from "../assets/images/gallery/thumbnails/13.webp";
import thumbnail14 from "../assets/images/gallery/thumbnails/14.webp";
import thumbnail15 from "../assets/images/gallery/thumbnails/15.webp";
import thumbnail16 from "../assets/images/gallery/thumbnails/16.webp";
import thumbnail17 from "../assets/images/gallery/thumbnails/17.webp";
import thumbnail18 from "../assets/images/gallery/thumbnails/18.webp";
import thumbnail19 from "../assets/images/gallery/thumbnails/19.webp";
import thumbnail20 from "../assets/images/gallery/thumbnails/20.webp";
import thumbnail21 from "../assets/images/gallery/thumbnails/21.webp";
import thumbnail22 from "../assets/images/gallery/thumbnails/22.webp";
import thumbnail23 from "../assets/images/gallery/thumbnails/23.webp";
import thumbnail24 from "../assets/images/gallery/thumbnails/24.webp";
import thumbnail25 from "../assets/images/gallery/thumbnails/25.webp";
import thumbnail26 from "../assets/images/gallery/thumbnails/26.webp";
import thumbnail27 from "../assets/images/gallery/thumbnails/27.webp";
import thumbnail28 from "../assets/images/gallery/thumbnails/28.webp";
import thumbnail29 from "../assets/images/gallery/thumbnails/29.webp";
import thumbnail30 from "../assets/images/gallery/thumbnails/30.webp";
import thumbnail31 from "../assets/images/gallery/thumbnails/31.webp";
import thumbnail32 from "../assets/images/gallery/thumbnails/32.webp";
import thumbnail33 from "../assets/images/gallery/thumbnails/33.webp";
import thumbnail34 from "../assets/images/gallery/thumbnails/34.webp";
import thumbnail35 from "../assets/images/gallery/thumbnails/35.webp";
import thumbnail36 from "../assets/images/gallery/thumbnails/36.webp";
import thumbnail37 from "../assets/images/gallery/thumbnails/37.webp";
import thumbnail38 from "../assets/images/gallery/thumbnails/38.webp";
import thumbnail39 from "../assets/images/gallery/thumbnails/39.webp";
import thumbnail40 from "../assets/images/gallery/thumbnails/40.webp";
import thumbnail41 from "../assets/images/gallery/thumbnails/41.webp";
import thumbnail42 from "../assets/images/gallery/thumbnails/42.webp";
import thumbnail43 from "../assets/images/gallery/thumbnails/43.webp";
import thumbnail44 from "../assets/images/gallery/thumbnails/44.webp";
import thumbnail45 from "../assets/images/gallery/thumbnails/45.webp";
import thumbnail46 from "../assets/images/gallery/thumbnails/46.webp";
import thumbnail47 from "../assets/images/gallery/thumbnails/47.webp";
import thumbnail48 from "../assets/images/gallery/thumbnails/48.webp";
import thumbnail49 from "../assets/images/gallery/thumbnails/49.webp";
import thumbnail50 from "../assets/images/gallery/thumbnails/50.webp";
import thumbnail51 from "../assets/images/gallery/thumbnails/51.webp";
import thumbnail52 from "../assets/images/gallery/thumbnails/52.webp";
import thumbnail53 from "../assets/images/gallery/thumbnails/53.webp";
import thumbnail54 from "../assets/images/gallery/thumbnails/54.webp";
import thumbnail55 from "../assets/images/gallery/thumbnails/55.webp";
import thumbnail56 from "../assets/images/gallery/thumbnails/56.webp";
import thumbnail57 from "../assets/images/gallery/thumbnails/57.webp";
import thumbnail58 from "../assets/images/gallery/thumbnails/58.webp";
import thumbnail59 from "../assets/images/gallery/thumbnails/59.webp";
import thumbnail60 from "../assets/images/gallery/thumbnails/60.webp";

const images = [
  {
    original: resim1,
    thumbnail: thumbnail1,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim2,
    thumbnail: thumbnail2,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim3,
    thumbnail: thumbnail3,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim4,
    thumbnail: thumbnail4,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim5,
    thumbnail: thumbnail5,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim6,
    thumbnail: thumbnail6,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim7,
    thumbnail: thumbnail7,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim8,
    thumbnail: thumbnail8,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim9,
    thumbnail: thumbnail9,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim10,
    thumbnail: thumbnail10,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim11,
    thumbnail: thumbnail11,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim12,
    thumbnail: thumbnail12,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim13,
    thumbnail: thumbnail13,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim14,
    thumbnail: thumbnail14,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim15,
    thumbnail: thumbnail15,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim16,
    thumbnail: thumbnail16,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim17,
    thumbnail: thumbnail17,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim18,
    thumbnail: thumbnail18,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim19,
    thumbnail: thumbnail19,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim20,
    thumbnail: thumbnail20,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim21,
    thumbnail: thumbnail21,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim22,
    thumbnail: thumbnail22,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim23,
    thumbnail: thumbnail23,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim24,
    thumbnail: thumbnail24,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim25,
    thumbnail: thumbnail25,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim26,
    thumbnail: thumbnail26,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim27,
    thumbnail: thumbnail27,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim28,
    thumbnail: thumbnail28,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim29,
    thumbnail: thumbnail29,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim30,
    thumbnail: thumbnail30,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim31,
    thumbnail: thumbnail31,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim32,
    thumbnail: thumbnail32,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim33,
    thumbnail: thumbnail33,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim34,
    thumbnail: thumbnail34,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim35,
    thumbnail: thumbnail35,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim36,
    thumbnail: thumbnail36,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim37,
    thumbnail: thumbnail37,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim38,
    thumbnail: thumbnail38,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim39,
    thumbnail: thumbnail39,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim40,
    thumbnail: thumbnail40,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim41,
    thumbnail: thumbnail41,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim42,
    thumbnail: thumbnail42,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim43,
    thumbnail: thumbnail43,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim44,
    thumbnail: thumbnail44,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim45,
    thumbnail: thumbnail45,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim46,
    thumbnail: thumbnail46,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim47,
    thumbnail: thumbnail47,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim48,
    thumbnail: thumbnail48,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim49,
    thumbnail: thumbnail49,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim50,
    thumbnail: thumbnail50,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim51,
    thumbnail: thumbnail51,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim52,
    thumbnail: thumbnail52,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim53,
    thumbnail: thumbnail53,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim54,
    thumbnail: thumbnail54,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim55,
    thumbnail: thumbnail55,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim56,
    thumbnail: thumbnail56,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim57,
    thumbnail: thumbnail57,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim58,
    thumbnail: thumbnail58,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim59,
    thumbnail: thumbnail59,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
  {
    original: resim60,
    thumbnail: thumbnail60,
    originalAlt: "gallery image",
    thumbnailAlt: "gallery thumbnail",
  },
];

const Gallery = () => {
  return (
    <section id="galeri" className="image-gallery-section">
      <h1 className="section-header">Galeri</h1>
      <div className="gallery-container">
        <ImageGallery
          useBrowserFullscreen={false}
          items={images}
          showThumbnails={true}
          showFullscreenButton={true}
          showPlayButton={false}
          lazyLoad={true}
        />
      </div>
    </section>
  );
};

export default Gallery;
