import React, { useEffect, useRef, useState } from "react";
import "./Campaigns.css";

const campaigns = [
  {
    image: <i className="fa-solid fa-ribbon campaign-icon"></i>,
    title: "Açılışımızı Kutluyoruz",
    description:
      "Eylül 2024'te faaliyete başlayan tesisimizde, misafirlerimize ilk aya özel fiyatlarla hizmet veriyoruz",
  },
  {
    image: <i className="fa-solid fa-person-skiing campaign-icon"></i>,
    title: "Kışın Yine Bekleriz",
    description:
      "Nisan-Eylül ayları arasında tesisimizde kalan misafirlerimize, aynı yıl Ekim-Mart ayları arasındaki ziyaretlerinde kışın ucuzlayan fiyatlara ek %10 indirim daha uyguluyoruz",
  },
  {
    image: <i className="fa-solid fa-user-group campaign-icon"></i>,
    title: "Arkadaşına Bizi Tavsiye Et",
    description:
      "Sizin tavsiyenizle ilk defa tesisimize gelen her misafir için, hem siz günlük %10 indirim hakkı elde edin, hem de tanıdığınız bu ilk ziyaretinde tatilinin bir günü için %10 daha az ödesin.",
  },
  // Add more bungalow objects as needed
];

const Campaigns = () => {
  const bungalowRefs = useRef([]);
  const [activePopup, setActivePopup] = useState(null);

  const openPopup = (agreement) => {
    setActivePopup(agreement);
  };
  const closePopup = () => {
    setActivePopup(null);
  };
  useEffect(() => {
    if (activePopup != null) {
      document.body.style.overflow = "hidden"; // Disable background scroll
    } else {
      document.body.style.overflow = ""; // Re-enable background scroll
    }

    return () => {
      document.body.style.overflow = ""; // Cleanup on unmount
    };
  }, [activePopup]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    bungalowRefs.current.forEach((bungalow) => {
      observer.observe(bungalow);
    });

    return () => {
      bungalowRefs.current.forEach((bungalow) => {
        observer.unobserve(bungalow);
      });
    };
  }, []);

  return (
    <section id="kampanyalar" className="campaigns-section">
      <h1 className="section-header">Kampanyalar</h1>
      <div className="campaigns-container">
        {campaigns.map((bungalow, index) => (
          <div
            className="campaign-card"
            key={index}
            ref={(el) => (bungalowRefs.current[index] = el)}
          >
            {bungalow.image}
            <div className="campaign-info">
              <h1 className="campaign-title">{bungalow.title}</h1>
              <p className="campaign-description">{bungalow.description}</p>
            </div>
          </div>
        ))}
        <p>
          Daha fazla bilgi için{" "}
          <button
            className="btn-campaigns"
            onClick={() => openPopup("campaign-details")}
          >
            Kampanya Detaylarına
          </button>{" "}
          göz atabilirsiniz.
        </p>
      </div>
      {activePopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <button className="close-popup" onClick={closePopup}>
                &times;
              </button>
            </div>
            <div className="popup-text">
              {activePopup === "campaign-details" && (
                <div className="popup-container">
                  <h4>Kampanya Detayları</h4>
                  <h5>1. Açılışımızı Kutluyoruz Kampanyası</h5>
                  <p>
                    <ul>
                      <li>
                        Kampanya tanıtım maksatlı olup sınırlı sayıda
                        rezervasyon için geçerlidir. (Sona erdiğinde web
                        sayfamızdan kaldırılacaktır.)
                      </li>
                      <li>
                        Kampanya, konaklama tarihi 01 Kasım 2024 tarihine kadar
                        olan rezervasyonlar için geçerli olup, kampanya
                        süresiince fiyatlar hafta içi gecelik 5500 TL, haftasonu
                        (Cuma, Cumartesi) günleri gecelik 6500 TL olacak şekilde
                        uygulanacaktır.
                      </li>
                      <li>
                        MAYA Sapanca Bungalov, kampanya süresince talebe göre
                        kampanya şartları ve fiyatları güncelleme hakkına
                        sahiptir.
                      </li>
                      <li>
                        Kapmanyadan hak kazanma şartı kapora ödenmesidir. Kapora
                        ödendiği anda; kampanyanın iptali, sona ermesi veya
                        fiyatın artması gibi durumlar misafire yansıtılmaz, hak
                        kaybı yaşatılmaz.
                      </li>
                      <li>Kampanya, Diğer kampanyalarla birleştirilemez.</li>
                    </ul>
                  </p>
                  <h5>2. Kışın Yine Bekleriz Kampanyası</h5>
                  <p>
                    <ul>
                      <li>
                        01 Nisan-30 Eylül 2024 tarihleri arasında en az iki gün
                        tesisimizde konaklayan misafirlere aynı yıl 01 Ekim-31
                        Mart tarihleri arasında yapacakları ilk konaklamalarda
                        rezervasyon sırasındaki fiyatlar üzerinden 10% indirim
                        uygulanır.
                      </li>
                      <li>
                        Misafirler, kampanya haklarının kontrol edilebilmesi
                        için; yaz sezonunda misafirimiz olduklarını ve kampanya
                        hakkları olduğunu tesise girişleri yapılırken
                        belirtmelidir.
                      </li>
                      <li>
                        Kampanya sonraki yıllara veya başka kişilere
                        devredilemez.
                      </li>
                      <li>
                        MAYA Sapanca Bungalov, kampanya tarih aralıklarını veya
                        indirim oranını değiştirme hakkına sahiptir. Uygulanacak
                        indirim oranı ilk ziyaretin çıkış gününde geçerli olan
                        indirim oranıdır ve bu oran müşteriye verilecek indirim
                        belgesinde yazılı olarak belirtilir.
                      </li>
                      <li>
                        MAYA Sapanca Bungalov, kampanyayı istediği zaman
                        sonlandırma hakkına sahiptir. Kampanyanın sonlanması
                        durumunda daha önceden edinilmiş haklar korunacak,
                        indirim hakkı olan misafirlere kampanya sona ermiş olsa
                        bile indirim uygulanacaktır.
                      </li>
                      <li>
                        Yaz sezonunda birden çok gerçekleşen konaklamalar için
                        kış sezonunda tek konaklamaya toplu indirim uygulanmaz;
                        yazın yapılan ziyaretlerle aynı sayıda kışın yapılacak
                        ziyarette ayrı ayrı, belirtilen indirim oranı üzerinden
                        indirim hakkı elde edilir.
                      </li>
                      <li>Kampanya, Diğer kampanyalarla birleştirilemez.</li>
                    </ul>
                    <h5>Arkadaşına Bizi Tavsiye Et Kampanyası</h5>
                    <ul>
                      <li>
                        Bu kampanya, tesisimizi ilk defa ziyaret eden yeni
                        misafirler için geçerlidir.
                      </li>
                      <li>
                        Daha önce tesisimizde konaklamış bir misafirimizin
                        tavsiyesiyle gelen yeni misafirin ilk konaklamasında,
                        tavsiye eden misafir bir sonraki ziyaretinde kullanmak
                        üzere bir günlük ücret için %10 indirim hakkı kazanır.
                      </li>
                      <li>
                        Yeni misafir, ilk ziyaretinde tatilinin bir günü için
                        %10 indirimden yararlanır.
                      </li>
                      <li>
                        Tavsiye üzerine tesisimizde kalan her yeni misafir için
                        tavsiye eden kişi ilave bir günlük ücrete %10 indirim
                        hakkı kazanır. Ancak, kazanılan indirimlerin toplamı
                        %50'yi geçemez. Tavsiye eden kişi en fazla bir gün için
                        %50 indirim kazanabilir.
                      </li>
                      <li>
                        Tavsiye eden kişi, bir sonraki konaklamasında mevcut
                        indirimini kullandıktan sonra, tavsiyesiyle gelen yeni
                        misafirler yoluyla indirim kazanmaya devam edebilir."
                      </li>
                      <li>
                        İndirimler mevcut misafirin bir sonraki konaklamasında
                        kullanılmalıdır. Hak kazanılan her %10 indirim, tavsiye
                        edilen misafirin konaklamasından itibaren 1 yıl süreyle
                        geçerlidir.
                      </li>
                      <li>
                        Tavsiye edilen misafirin, rezervasyon sırasında tavsiye
                        eden kişinin cep telefonu numarasını belirtmesi
                        gerekmektedir. Bu bilginin verilmemesi durumunda
                        kampanya geçersiz sayılır.
                      </li>
                      <li>
                        Bu kampanya başka kampanyalarla birleştirilemez, veya
                        kazanılan haklar bir başkasına devredilemez.
                      </li>
                      <li>
                        MAYA Sapanca Bungalov, kampanya şartlarını değiştirme
                        veya kampanyayı sonlandırma hakkını saklı tutar.
                        Kampanyanın sonlandırılması durumunda daha önceden
                        edinilmiş haklar korunacaktır.
                      </li>
                    </ul>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Campaigns;
