import React from "react";
import "./Facilities.css";

const FacilityList = () => {
  return (
    <div className="facilities-container">
      {/* KAHVALTI */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: 24,
              width: 24,
              fill: "currentcolor",
            }}
          >
            <path d="M2 31a1 1 0 0 1-1-1 9 9 0 0 1 17.95-1H21c.74 0 1.43-.27 1.97-.74A2.99 2.99 0 0 0 24 26.2L24 26V14H13v6h-2v-7a1 1 0 0 1 .88-1H25a1 1 0 0 1 1 .88V15h4a1 1 0 0 1 1 .88V25a1 1 0 0 1-.88 1H26a4.99 4.99 0 0 1-1.71 3.77 4.98 4.98 0 0 1-3.03 1.22L21 31zm3.85-6.64a7 7 0 0 0-2.7 4.16l-.05.3-.03.18h4.1zM10 23c-.82 0-1.61.14-2.35.4l1.6 5.6h1.5l1.6-5.6a6.97 6.97 0 0 0-1.86-.38l-.25-.02zm4.15 1.36L12.82 29h4.1l-.02-.18a7 7 0 0 0-2.75-4.46zM29 17h-3v7h3zM20 1c0 2.06-.48 3.34-1.77 5.42l-.75 1.19C16.6 9 16.2 9.9 16.06 11h-2.02c.15-1.61.71-2.84 1.91-4.73l.57-.88c1.11-1.79 1.47-2.78 1.47-4.4zm5 0c0 2.06-.48 3.34-1.77 5.42l-.75 1.19C21.6 9 21.2 9.9 21.06 11h-2.02c.15-1.61.71-2.84 1.91-4.73l.57-.88c1.11-1.79 1.47-2.78 1.47-4.4z"></path>
          </svg>
        </i>
        Serpme Kahvaltı
      </div>
      {/* HAVUZ */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M1.418,23.306c-0.277,0-0.555-0.03-0.826-0.088c-0.196-0.042-0.364-0.158-0.472-0.326
        c-0.109-0.168-0.146-0.369-0.103-0.565c0.074-0.343,0.383-0.592,0.735-0.592c0.052,0,0.103,0.005,0.154,0.016
        c0.165,0.036,0.334,0.053,0.502,0.053c1.688,0,3.103-1.744,3.117-1.761c0.143-0.181,0.356-0.284,0.585-0.284
        c0.006,0,0.031,0.001,0.037,0.001c0.237,0.01,0.459,0.135,0.591,0.335c0.65,0.98,1.738,1.605,2.912,1.674
        c1.283-0.073,2.466-0.698,3.25-1.717c0.141-0.183,0.363-0.293,0.594-0.293c0.022,0,0.045,0.001,0.067,0.003
        c0.256,0.022,0.48,0.172,0.599,0.401c0.505,0.969,1.499,1.586,2.592,1.607c1.168-0.029,2.255-0.649,2.892-1.66
        c0.138-0.219,0.375-0.351,0.635-0.351c0.262,0,0.5,0.133,0.637,0.354c0.041,0.065,1.057,1.652,2.661,1.652h0
        c0.172,0,0.347-0.019,0.521-0.055c0.051-0.01,0.102-0.016,0.153-0.016c0.353,0,0.662,0.251,0.734,0.597
        c0.084,0.405-0.177,0.803-0.582,0.887c-0.278,0.058-0.559,0.087-0.834,0.087l0,0c-1.51,0-2.631-0.863-3.285-1.552
        c-0.92,0.962-2.184,1.523-3.53,1.556c-1.301-0.026-2.518-0.601-3.346-1.56c-1.018,0.941-2.324,1.491-3.723,1.559
        c-0.006,0-0.03,0.001-0.036,0.001l-0.033-0.001c-1.35-0.066-2.621-0.651-3.548-1.62C4.302,22.369,3.002,23.306,1.418,23.306z"
              ></path>
              <path
                d="M1.418,18.806c-0.277,0-0.555-0.03-0.826-0.088c-0.196-0.042-0.364-0.158-0.472-0.326
        c-0.109-0.168-0.146-0.369-0.103-0.565c0.074-0.343,0.383-0.592,0.735-0.592c0.052,0,0.103,0.005,0.154,0.016
        c0.165,0.036,0.333,0.053,0.502,0.053c1.688,0,3.103-1.743,3.117-1.761c0.143-0.181,0.357-0.284,0.585-0.284
        c0.006,0,0.03,0.001,0.036,0.001c0.238,0.01,0.459,0.135,0.592,0.335c0.65,0.98,1.738,1.605,2.912,1.674
        c1.283-0.073,2.466-0.698,3.25-1.717c0.141-0.183,0.364-0.293,0.595-0.293c0.021,0,0.043,0.001,0.064,0.003
        c0.257,0.022,0.481,0.172,0.6,0.401c0.505,0.969,1.499,1.586,2.592,1.607c1.168-0.029,2.255-0.649,2.892-1.66
        c0.138-0.219,0.375-0.351,0.635-0.351c0.262,0,0.5,0.133,0.637,0.354c0.041,0.065,1.057,1.652,2.661,1.652
        c0.172,0,0.347-0.018,0.521-0.055c0.051-0.01,0.102-0.016,0.153-0.016c0.354,0,0.662,0.251,0.734,0.597
        c0.084,0.405-0.177,0.803-0.582,0.887c-0.278,0.058-0.559,0.087-0.834,0.087c-1.51,0-2.631-0.863-3.284-1.552
        c-0.92,0.962-2.184,1.523-3.53,1.556c-1.301-0.026-2.518-0.601-3.346-1.56c-1.018,0.941-2.324,1.491-3.723,1.559
        c-0.006,0-0.03,0.001-0.036,0.001l-0.033-0.001c-1.35-0.066-2.621-0.651-3.548-1.62C4.302,17.869,3.002,18.806,1.418,18.806z"
              ></path>
              <path
                d="M19.5,11.945c-1.861,0-3.375-1.514-3.375-3.375s1.514-3.375,3.375-3.375s3.375,1.514,3.375,3.375
        S21.361,11.945,19.5,11.945z M19.5,6.695c-1.034,0-1.875,0.841-1.875,1.875s0.841,1.875,1.875,1.875s1.875-0.841,1.875-1.875
        S20.534,6.695,19.5,6.695z"
              ></path>
              <path
                d="M7.379,14.75c-0.26,0-0.498-0.132-0.636-0.352c-0.106-0.169-0.14-0.37-0.095-0.566c0.045-0.195,0.163-0.361,0.333-0.468
        l3.085-1.932L7.836,8.888c-1.362-1.556-1.204-3.93,0.352-5.292c0.24-0.21,0.507-0.389,0.793-0.532l4.262-2.135
        c0.313-0.159,0.665-0.244,1.015-0.244c0.236,0,0.47,0.037,0.697,0.111c0.571,0.185,1.037,0.582,1.31,1.118
        c0.273,0.535,0.321,1.145,0.136,1.716c-0.185,0.572-0.583,1.037-1.118,1.31l-3.426,1.713l5.385,6.146
        c0.132,0.15,0.197,0.344,0.184,0.544c-0.013,0.2-0.104,0.383-0.255,0.514c-0.136,0.12-0.312,0.186-0.493,0.186
        c-0.217,0-0.422-0.094-0.565-0.257l-5.377-6.145c-0.082-0.092-0.153-0.197-0.21-0.31c-0.182-0.356-0.214-0.762-0.091-1.144
        c0.123-0.381,0.388-0.692,0.745-0.875l3.406-1.703c0.191-0.097,0.326-0.251,0.388-0.444c0.062-0.191,0.046-0.394-0.045-0.572
        c-0.091-0.178-0.246-0.311-0.437-0.373c-0.076-0.025-0.155-0.037-0.233-0.037c-0.118,0-0.232,0.028-0.339,0.082L9.652,4.406
        C9.48,4.491,9.32,4.599,9.176,4.725C8.242,5.542,8.147,6.967,8.964,7.9l2.809,3.206c0.145,0.165,0.21,0.385,0.178,0.603
        c-0.032,0.218-0.157,0.41-0.344,0.526l-3.831,2.4C7.657,14.711,7.52,14.75,7.379,14.75z"
              ></path>
            </g>
          </svg>
        </i>
        Isıtmalı Havuz
      </div>
      {/* JAKUZİ */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: 24,
              width: 24,
              fill: "currentcolor",
            }}
          >
            <path d="M9.5 2a4.5 4.5 0 0 1 3.53 7.3c.6.21 1.17.54 1.66.98l.19.19L17.4 13H31v2h-2v14a2 2 0 0 1-1.85 2H5a2 2 0 0 1-2-1.85V15H1v-2h2.1a5 5 0 0 1 2.96-3.6A4.5 4.5 0 0 1 9.5 2zm7.08 13H5v14h22V15h-7.59l3.3 3.3-1.42 1.4zM9.5 4a2.5 2.5 0 0 0-1 4.8V11H8a3 3 0 0 0-2.83 2h9.41l-1.12-1.12a3 3 0 0 0-1.92-.87l-.2-.01h-.84V8.8a2.5 2.5 0 0 0-1-4.8zm15.49-3a6.96 6.96 0 0 1-1.8 4.07l-.45.46A8.97 8.97 0 0 0 20.35 11h-2a10.97 10.97 0 0 1 3.2-7.12A4.96 4.96 0 0 0 22.97 1zm2 0h2a10.96 10.96 0 0 1-3.2 7.12A4.97 4.97 0 0 0 24.38 11h-2a6.97 6.97 0 0 1 1.8-4.06l.44-.47A8.96 8.96 0 0 0 26.99 1z"></path>
          </svg>
        </i>
        Jakuzi
      </div>
      {/* KLİMA */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M21.75,13.5c-0.414,0-0.75-0.336-0.75-0.75S21.336,12,21.75,12s0.75-0.336,0.75-0.75V10.5h-21v0.75
        C1.5,11.664,1.836,12,2.25,12S3,12.336,3,12.75S2.664,13.5,2.25,13.5C1.009,13.5,0,12.491,0,11.25v-7.5C0,1.682,1.682,0,3.75,0
        h16.5C22.318,0,24,1.682,24,3.75v7.5C24,12.491,22.991,13.5,21.75,13.5z M22.5,9V3.75c0-1.241-1.009-2.25-2.25-2.25H3.75
        C2.509,1.5,1.5,2.509,1.5,3.75V9H22.5z"
              ></path>
              <circle cx="19.5" cy="4.5" r="1.125"></circle>
              <path
                d="M2.25,21c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75c1.241,0,2.25-1.009,2.25-2.25v-3
        c0-0.414,0.336-0.75,0.75-0.75S6,13.836,6,14.25v3C6,19.318,4.318,21,2.25,21z"
              ></path>
              <path
                d="M8.25,24c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75S9,22.164,9,21.75v-7.5c0-0.414,0.336-0.75,0.75-0.75
        s0.75,0.336,0.75,0.75v7.5C10.5,22.991,9.491,24,8.25,24z"
              ></path>
              <path
                d="M21.75,21C19.682,21,18,19.318,18,17.25v-3c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v3
        c0,1.241,1.009,2.25,2.25,2.25c0.414,0,0.75,0.336,0.75,0.75S22.164,21,21.75,21z"
              ></path>
              <path
                d="M15.75,24c-1.241,0-2.25-1.009-2.25-2.25v-7.5c0-0.414,0.336-0.75,0.75-0.75S15,13.836,15,14.25v7.5
        c0,0.414,0.336,0.75,0.75,0.75s0.75,0.336,0.75,0.75S16.164,24,15.75,24z"
              ></path>
            </g>
          </svg>
        </i>
        Klima
      </div>
      {/* BUZDOLABI */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            id="Regular"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M17.25,24c-0.414,0-0.75-0.336-0.75-0.75V22.5h-9v0.75C7.5,23.664,7.164,24,6.75,24S6,23.664,6,23.25V22.5H4.799
        C3.807,22.5,3,21.693,3,20.701V1.799C3,0.807,3.807,0,4.799,0h14.402C20.193,0,21,0.807,21,1.799v18.902
        c0,0.992-0.807,1.799-1.799,1.799H18v0.75C18,23.664,17.664,24,17.25,24z M4.5,20.701C4.5,20.866,4.634,21,4.799,21h14.402
        c0.165,0,0.299-0.134,0.299-0.299V9h-15V20.701z M19.5,7.5V1.799c0-0.165-0.134-0.299-0.299-0.299H4.799
        C4.634,1.5,4.5,1.634,4.5,1.799V7.5H19.5z"
              ></path>
              <path
                d="M6.75,18C6.336,18,6,17.664,6,17.25v-4.5C6,12.336,6.336,12,6.75,12s0.75,0.336,0.75,0.75v4.5C7.5,17.664,7.164,18,6.75,18
        z"
              ></path>
              <path d="M6.75,6C6.336,6,6,5.664,6,5.25v-1.5C6,3.336,6.336,3,6.75,3S7.5,3.336,7.5,3.75v1.5C7.5,5.664,7.164,6,6.75,6z"></path>
            </g>
          </svg>
        </i>
        Mini Buzdolabı
      </div>
      {/* OCAK */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            id="Regular"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M6.378,11.25c-2.371,0-4.301-1.927-4.301-4.295C1.98,5.492,2.77,4.064,4.092,3.342c0.11-0.06,0.234-0.092,0.36-0.092
        c0.115,0,0.231,0.027,0.335,0.079c0.22,0.11,0.372,0.321,0.407,0.564c0.028,0.192,0.142,0.356,0.301,0.453
        C5.887,3.294,5.69,2.103,4.96,1.232C4.75,0.981,4.726,0.626,4.901,0.349C5.04,0.131,5.277,0,5.535,0
        c0.063,0,0.127,0.008,0.188,0.024c3.131,0.821,5.209,3.756,4.943,6.981c-0.042,2.335-1.924,4.21-4.283,4.245
        C6.381,11.25,6.379,11.25,6.378,11.25z M4.179,5.161C3.755,5.635,3.533,6.262,3.575,6.906C3.577,8.485,4.831,9.75,6.372,9.75
        c1.542-0.023,2.773-1.256,2.796-2.807c0-0.017,0.001-0.034,0.003-0.052C9.323,5.095,8.51,3.357,7.094,2.31
        C7.32,3.409,7.145,4.567,6.58,5.555C6.456,5.771,6.234,5.911,5.987,5.93C5.932,5.934,5.878,5.936,5.824,5.936
        C5.186,5.936,4.581,5.643,4.179,5.161z"
              ></path>
              <path
                d="M17.051,11.25c-2.371,0-4.301-1.927-4.301-4.295c-0.097-1.463,0.694-2.891,2.016-3.613C14.875,3.282,15,3.25,15.125,3.25
        c0.115,0,0.231,0.027,0.334,0.079c0.22,0.11,0.372,0.32,0.408,0.563c0.028,0.192,0.142,0.356,0.301,0.452
        c0.392-1.05,0.195-2.241-0.534-3.112c-0.211-0.251-0.234-0.606-0.059-0.883C15.712,0.131,15.949,0,16.208,0
        c0.063,0,0.127,0.008,0.189,0.024c3.131,0.821,5.209,3.756,4.942,6.98c-0.042,2.335-1.924,4.21-4.283,4.245
        C17.054,11.25,17.052,11.25,17.051,11.25z M14.852,5.16c-0.424,0.474-0.646,1.102-0.604,1.746c0.001,1.579,1.256,2.844,2.796,2.844
        c1.542-0.023,2.773-1.256,2.796-2.807c0-0.021,0.001-0.036,0.002-0.052c0.152-1.797-0.661-3.535-2.076-4.582
        c0.226,1.099,0.05,2.258-0.517,3.245c-0.124,0.216-0.346,0.356-0.594,0.375c-0.054,0.004-0.107,0.006-0.161,0.006
        C15.859,5.936,15.254,5.642,14.852,5.16z"
              ></path>
              <path
                d="M1.75,24C0.785,24,0,23.215,0,22.25v-7c0-0.965,0.785-1.75,1.75-1.75H6v-0.75C6,12.337,6.336,12,6.75,12
        s0.75,0.336,0.75,0.75v0.75h9v-0.75c0-0.414,0.336-0.75,0.75-0.75S18,12.337,18,12.75v0.75h4.25c0.965,0,1.75,0.785,1.75,1.75v7
        c0,0.965-0.785,1.75-1.75,1.75H1.75z M1.75,15c-0.138,0-0.25,0.112-0.25,0.25v7c0,0.138,0.112,0.25,0.25,0.25h20.5
        c0.138,0,0.25-0.112,0.25-0.25v-7c0-0.138-0.112-0.25-0.25-0.25H1.75z"
              ></path>
              <path
                d="M6.75,21c-1.241,0-2.25-1.009-2.25-2.25s1.009-2.25,2.25-2.25S9,17.509,9,18.75S7.991,21,6.75,21z M6.75,18
        C6.336,18,6,18.337,6,18.75s0.336,0.75,0.75,0.75s0.75-0.336,0.75-0.75S7.164,18,6.75,18z"
              ></path>
              <path
                d="M17.25,21C16.009,21,15,19.991,15,18.75s1.009-2.25,2.25-2.25s2.25,1.009,2.25,2.25S18.491,21,17.25,21z M17.25,18
        c-0.414,0-0.75,0.336-0.75,0.75s0.336,0.75,0.75,0.75S18,19.164,18,18.75S17.664,18,17.25,18z"
              ></path>
            </g>
          </svg>
        </i>
        Elektrikli Ocak
      </div>
      {/* SU ISITICI */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            id="Regular"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M7.5,24c-1.109,0-2.059-0.829-2.224-1.913l-3.686-3.686c-0.543-0.542-0.841-1.263-0.841-2.031
        c0-1.583,1.288-2.871,2.871-2.871h1.917c0.158-0.613,0.382-1.208,0.666-1.775c0.019-0.048,0.042-0.094,0.07-0.136
        c0.147-0.281,0.307-0.552,0.477-0.808V7.875c-0.099-4.209,3.255-7.745,7.477-7.875c4.257,0.129,7.622,3.669,7.523,7.892v2.889
        c0.17,0.257,0.33,0.528,0.477,0.809c0.027,0.041,0.05,0.086,0.068,0.133c0.633,1.26,0.954,2.615,0.954,4.026v6
        c0,1.241-1.009,2.25-2.25,2.25H7.5z M7.377,12.75c-0.416,0.948-0.627,1.956-0.627,3v6c0,0.414,0.336,0.75,0.75,0.75H21
        c0.414,0,0.75-0.336,0.75-0.75v-6c0-1.044-0.211-2.052-0.627-3H7.377z M3.621,15c-0.366,0-0.71,0.143-0.969,0.401
        c-0.259,0.259-0.402,0.603-0.402,0.969c0,0.366,0.143,0.711,0.402,0.97L5.25,19.94V15.75c0-0.244,0.011-0.495,0.033-0.75H3.621z
         M20.246,11.25c-1.416-1.891-3.61-3-5.996-3c-2.386,0-4.58,1.109-5.996,3H20.246z M14.227,1.5C10.862,1.604,8.17,4.456,8.25,7.858
        l0,1.191c1.13-1.011,2.505-1.726,3.977-2.067C12.079,6.678,12,6.343,12,6c0-1.241,1.009-2.25,2.25-2.25S16.5,4.76,16.5,6
        c0,0.342-0.079,0.677-0.227,0.981c1.473,0.341,2.847,1.056,3.977,2.067V7.875C20.33,4.46,17.628,1.604,14.227,1.5z M14.25,5.25
        c-0.414,0-0.75,0.336-0.75,0.75s0.336,0.75,0.75,0.75S15,6.414,15,6S14.664,5.25,14.25,5.25z"
              ></path>
            </g>
          </svg>
        </i>
        Su Isıtıcı &amp; Çay Makinesi
      </div>
      {/* KAHVE MAK */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            id="Regular"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M12,20.25c-3.253,0-3.86-2.692-4.223-4.3c-0.046-0.202-0.089-0.396-0.135-0.576c-0.1-0.389-0.042-0.793,0.163-1.138
        c0.205-0.345,0.531-0.59,0.919-0.689c0.13-0.033,0.263-0.042,0.364-0.047l6.663,0c1.654,0,3,1.346,3,3
        c0,0.414-0.336,0.75-0.75,0.75s-0.75-0.336-0.75-0.75c0-0.549-0.301-1.042-0.759-1.304C16.442,17.044,16.035,20.25,12,20.25z
         M9.095,15c0.049,0.194,0.096,0.402,0.146,0.62c0.363,1.61,0.706,3.13,2.76,3.13c2.589,0,2.951-1.731,2.994-3.75H9.097H9.095z"
              ></path>
              <path
                d="M3,24c-1.241,0-2.25-1.009-2.25-2.25c0-0.96,0.615-1.808,1.5-2.121V7.482C1.403,7.36,0.75,6.63,0.75,5.75v-4
        C0.75,0.785,1.535,0,2.5,0h19c0.965,0,1.75,0.785,1.75,1.75v4c0,0.88-0.653,1.61-1.5,1.732v12.146c0.885,0.314,1.5,1.162,1.5,2.121
        c0,1.241-1.009,2.25-2.25,2.25H3z M3,21c-0.414,0-0.75,0.336-0.75,0.75S2.586,22.5,3,22.5h18c0.414,0,0.75-0.336,0.75-0.75
        S21.414,21,21,21H3z M20.25,19.5v-12h-4.5v2.25c0,0.414-0.336,0.75-0.75,0.75h-0.75v0.75c0,0.414-0.336,0.75-0.75,0.75
        s-0.75-0.336-0.75-0.75V10.5h-1.5v0.75c0,0.414-0.336,0.75-0.75,0.75s-0.75-0.336-0.75-0.75V10.5H9c-0.414,0-0.75-0.336-0.75-0.75
        V7.5h-4.5v12H20.25z M14.25,9V7.5h-4.5V9H14.25z M2.5,1.5c-0.138,0-0.25,0.112-0.25,0.25v4C2.25,5.888,2.362,6,2.5,6h19
        c0.138,0,0.25-0.112,0.25-0.25v-4c0-0.138-0.112-0.25-0.25-0.25H2.5z"
              ></path>
              <circle cx="19.125" cy="3.375" r="1.125"></circle>
              <circle cx="15.375" cy="3.375" r="1.125"></circle>
            </g>
          </svg>
        </i>
        Kahve Makinesi
      </div>
      {/* SICAK SU
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M1.501,24c-0.414,0-0.75-0.336-0.75-0.75v-15C0.751,3.701,4.452,0,9.001,0h6c0.414,0,0.75,0.336,0.75,0.75
        c0,2.068-1.682,3.75-3.75,3.75h-1.5c-1.781,0-3.32,1.288-3.672,3h12.672c1.241,0,2.25,1.009,2.25,2.25v1.5
        c0,1.241-1.009,2.25-2.25,2.25h-10.5c-1.241,0-2.25,1.009-2.25,2.25v7.5c0,0.414-0.336,0.75-0.75,0.75H1.501z M5.251,22.5v-6.75
        c0-2.068,1.682-3.75,3.75-3.75h10.5c0.414,0,0.75-0.336,0.75-0.75v-1.5c0-0.414-0.336-0.75-0.75-0.75H2.251v13.5H5.251z M5.304,7.5
        C5.672,4.95,7.89,3,10.501,3h1.5c0.962,0,1.808-0.621,2.121-1.5H9.001c-3.46,0-6.334,2.605-6.709,6H5.304z"
              ></path>
              <path
                d="M15.751,16.5c-0.414,0-0.75-0.336-0.75-0.75S15.337,15,15.751,15h3.75c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75
        H15.751z"
              ></path>
              <path
                d="M18.001,24c-0.414,0-0.75-0.336-0.75-0.75v-4.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v4.5
        C18.751,23.664,18.414,24,18.001,24z"
              ></path>
              <path
                d="M22.501,23.25c-0.309,0-0.582-0.185-0.696-0.472l-1.5-3.75c-0.074-0.186-0.072-0.39,0.007-0.574
        c0.079-0.184,0.225-0.327,0.411-0.401C20.811,18.018,20.905,18,21,18c0.309,0,0.582,0.185,0.697,0.472l1.5,3.75
        c0.074,0.186,0.072,0.39-0.007,0.574c-0.079,0.184-0.225,0.327-0.411,0.401C22.69,23.232,22.597,23.25,22.501,23.25z"
              ></path>
              <path
                d="M13.501,23.25c-0.096,0-0.19-0.018-0.279-0.054c-0.186-0.074-0.332-0.217-0.41-0.401c-0.079-0.184-0.082-0.388-0.007-0.574
        l1.5-3.75C14.419,18.185,14.692,18,15.001,18c0.095,0,0.189,0.018,0.278,0.054c0.186,0.074,0.332,0.217,0.411,0.401
        c0.079,0.184,0.082,0.388,0.007,0.574l-1.5,3.75C14.083,23.065,13.809,23.25,13.501,23.25z"
              ></path>
            </g>
          </svg>
        </i>
        Sıcak Su
      </div> */}
      {/* ŞAMPUAN */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M4.737,21c-2.068,0-3.75-1.682-3.75-3.75v-7.5c0-1.799,1.268-3.323,3-3.673V5.25C3.987,4.009,4.997,3,6.237,3h0.75V1.5
        H4.48c-0.601,0-1.166,0.234-1.591,0.659L2.267,2.781C2.125,2.922,1.937,3,1.737,3C1.537,3,1.348,2.922,1.206,2.78
        C0.914,2.487,0.915,2.012,1.207,1.719l0.622-0.621C2.537,0.39,3.479,0,4.48,0h7.007c0.414,0,0.75,0.336,0.75,0.75
        s-0.336,0.75-0.75,0.75h-3V3h0.75c1.241,0,2.25,1.009,2.25,2.25v0.827c1.732,0.351,3,1.874,3,3.673v0.75
        c0,0.414-0.336,0.75-0.75,0.75s-0.75-0.336-0.75-0.75V9.75c0-1.241-1.009-2.25-2.25-2.25h-6c-1.241,0-2.25,1.009-2.25,2.25v7.5
        c0,1.241,1.009,2.25,2.25,2.25h3.75c0.414,0,0.75,0.336,0.75,0.75S8.901,21,8.487,21H4.737z M9.987,6V5.25
        c0-0.414-0.336-0.75-0.75-0.75h-3c-0.414,0-0.75,0.336-0.75,0.75V6H9.987z"
              ></path>
              <path
                d="M16.737,20.25c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S18.391,20.25,16.737,20.25z M16.737,15.75
        c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S17.564,15.75,16.737,15.75z"
              ></path>
              <path
                d="M16.737,24c-0.414,0-0.75-0.336-0.75-0.75v-1.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v1.5
        C17.487,23.664,17.151,24,16.737,24z"
              ></path>
              <path
                d="M16.737,12.75c-0.414,0-0.75-0.336-0.75-0.75v-1.5c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75V12
        C17.487,12.414,17.151,12.75,16.737,12.75z"
              ></path>
              <path
                d="M22.258,20.812c-0.132,0-0.261-0.035-0.375-0.101l-1.299-0.75c-0.174-0.1-0.298-0.262-0.349-0.455
        c-0.052-0.193-0.025-0.396,0.075-0.569c0.133-0.231,0.382-0.375,0.65-0.375c0.132,0,0.261,0.035,0.375,0.101l1.298,0.75
        c0.174,0.1,0.298,0.262,0.349,0.455c0.052,0.193,0.025,0.396-0.075,0.569C22.774,20.668,22.525,20.812,22.258,20.812z"
              ></path>
              <path
                d="M12.515,15.187c-0.132,0-0.261-0.035-0.375-0.101l-1.299-0.75c-0.173-0.1-0.298-0.262-0.349-0.455
        c-0.052-0.193-0.025-0.396,0.075-0.569c0.134-0.231,0.383-0.375,0.65-0.375c0.131,0,0.261,0.035,0.375,0.101l1.299,0.75
        c0.174,0.1,0.298,0.262,0.349,0.455c0.052,0.193,0.025,0.396-0.075,0.569C13.031,15.043,12.782,15.187,12.515,15.187z"
              ></path>
              <path
                d="M20.959,15.187c-0.268,0-0.517-0.144-0.65-0.375c-0.1-0.173-0.126-0.375-0.075-0.569c0.052-0.194,0.176-0.355,0.349-0.455
        l1.299-0.75c0.114-0.066,0.243-0.101,0.375-0.101c0.268,0,0.517,0.144,0.65,0.375c0.1,0.174,0.127,0.376,0.075,0.569
        c-0.052,0.194-0.176,0.355-0.349,0.455l-1.299,0.75C21.221,15.152,21.091,15.187,20.959,15.187z"
              ></path>
              <path
                d="M11.217,20.812c-0.267,0-0.516-0.144-0.65-0.375c-0.1-0.174-0.127-0.376-0.075-0.569c0.052-0.194,0.176-0.355,0.349-0.455
        l1.299-0.75c0.114-0.066,0.243-0.101,0.375-0.101c0.267,0,0.516,0.144,0.65,0.375c0.1,0.173,0.126,0.375,0.075,0.569
        c-0.052,0.194-0.176,0.355-0.349,0.455l-1.299,0.75C11.478,20.777,11.348,20.812,11.217,20.812z"
              ></path>
            </g>
          </svg>
        </i>
        Şampuan
      </div>
      {/* HAVLU */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M4.5,24c-0.414,0-0.75-0.336-0.75-0.75V16.5H3c-1.241,0-2.25-1.009-2.25-2.25c0-5.262,6.02-7.931,10.5-8.224V5.905
        C9.947,5.567,9.001,4.371,9.001,3c0-1.654,1.346-3,3-3c1.654,0,3,1.346,3,3c0,0.414-0.336,0.75-0.75,0.75s-0.75-0.336-0.75-0.75
        c0-0.827-0.673-1.5-1.5-1.5s-1.5,0.673-1.5,1.5c0,0.827,0.673,1.5,1.499,1.5c0.413,0,0.75,0.337,0.75,0.75v0.776
        c4.48,0.293,10.5,2.962,10.5,8.224c0,1.241-1.009,2.25-2.25,2.25h-0.75v6.75c0,0.414-0.336,0.75-0.75,0.75H4.5z M18.75,22.5v-8.25
        c0-0.414-0.336-0.75-0.75-0.75H6c-0.414,0-0.75,0.336-0.75,0.75v8.25H18.75z M21,15c0.414,0,0.75-0.336,0.75-0.75
        c0-2.569-1.883-4.173-3.463-5.066C16.087,7.941,13.526,7.5,12,7.5c-1.5,0-4.026,0.426-6.205,1.628
        C4.177,10.019,2.25,11.631,2.25,14.25C2.25,14.664,2.586,15,3,15h0.75v-0.75C3.75,13.009,4.759,12,6,12h12
        c1.241,0,2.25,1.009,2.25,2.25L21,15z"
              ></path>
            </g>
          </svg>
        </i>
        Havlu
      </div>
      {/* SAÇ KURUTMA MAK */}
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M16.1,24c-0.139,0-0.278-0.008-0.413-0.023c-0.994-0.11-1.887-0.602-2.513-1.384c-0.626-0.782-0.91-1.761-0.799-2.757
        l0.566-5.091c-4.227-0.533-8.879-1.76-11.316-2.457C0.665,12.013-0.003,11.124,0,10.125V4.873
        c-0.004-0.996,0.664-1.886,1.624-2.162C4.482,1.896,11.651,0,16.5,0C20.635,0,24,3.365,24,7.5c0,2.351-1.118,4.578-3,5.994v6.756
        c0,2.068-1.682,3.75-3.75,3.75H16.1z M13.867,20.002c-0.066,0.597,0.104,1.185,0.479,1.654c0.375,0.469,0.911,0.764,1.509,0.831
        c0.082,0.009,0.164,0.014,0.245,0.014h1.15c1.241,0,2.25-1.009,2.25-2.25v-5.877c-0.017,0.007-0.114,0.049-0.114,0.049
        c-0.096,0.041-0.196,0.08-0.296,0.117l-0.032,0.011c-0.329,0.118-0.646,0.209-0.964,0.278l-0.109,0.024
        c-0.085,0.017-0.17,0.032-0.254,0.046c-0.055,0.009-0.111,0.018-0.168,0.026c-0.077,0.011-0.154,0.021-0.23,0.029
        c-0.07,0.008-0.141,0.014-0.211,0.02l-0.046,0.004c-0.051,0.004-0.101,0.008-0.152,0.011c-0.119,0.007-0.238,0.01-0.357,0.011
        L16.511,15c-0.008,0-0.05-0.001-0.056-0.001c-0.346,0-0.734-0.012-1.165-0.035c0,0-0.03-0.001-0.03-0.001
        c-0.275-0.016-0.552-0.035-0.828-0.058L13.867,20.002z M13.868,13.344c0.164,0.018,0.345,0.035,0.526,0.052l0.195,0.017
        c0.642,0.053,1.285,0.083,1.911,0.087v-12c-3.544,0-8.427,1.062-12,1.985v8.031c2.55,0.659,6.146,1.483,9.327,1.824
        C13.841,13.34,13.855,13.342,13.868,13.344z M18,13.309c0.034-0.009,0.068-0.018,0.102-0.028c0.109-0.03,0.216-0.063,0.322-0.099
        c0,0,0.113-0.038,0.153-0.053c0.069-0.025,0.137-0.053,0.205-0.081l0.13-0.056c0.114-0.05,0.227-0.104,0.337-0.162
        c0,0,0.097-0.05,0.123-0.064c0.064-0.035,0.128-0.073,0.192-0.11c0.042-0.025,0.083-0.051,0.125-0.078
        C21.449,11.468,22.5,9.57,22.5,7.5c0-2.757-1.867-5.132-4.5-5.808V13.309z M3,3.884C2.641,3.982,2.317,4.073,2.037,4.153
        C1.72,4.244,1.499,4.539,1.5,4.87v5.257c-0.001,0.333,0.22,0.628,0.538,0.719c0.28,0.08,0.604,0.171,0.962,0.27V3.884z"
              ></path>
            </g>
          </svg>
        </i>
        Saç Kurutma Makinesi
      </div>

      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M7.5,22.125c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h3.75v-2.25h-9.5c-0.965,0-1.75-0.785-1.75-1.75v-13
        c0-0.965,0.785-1.75,1.75-1.75h20.5c0.965,0,1.75,0.785,1.75,1.75v13c0,0.965-0.785,1.75-1.75,1.75h-9.5v2.25h3.75
        c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H7.5z M1.75,3.375c-0.138,0-0.25,0.112-0.25,0.25v13
        c0,0.138,0.112,0.25,0.25,0.25h20.5c0.138,0,0.25-0.112,0.25-0.25v-13c0-0.138-0.112-0.25-0.25-0.25H1.75z"
              ></path>
            </g>
          </svg>
        </i>
        55 Ekran Smart TV
      </div>

      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            height="24px"
            width="24px"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
          >
            <g fill="currentcolor">
              <path
                d="M12,22.53c-2.068,0-3.75-1.682-3.75-3.75s1.682-3.75,3.75-3.75c2.068,0,3.75,1.682,3.75,3.75S14.068,22.53,12,22.53z
         M12,16.53c-1.241,0-2.25,1.009-2.25,2.25s1.009,2.25,2.25,2.25c1.241,0,2.25-1.009,2.25-2.25S13.241,16.53,12,16.53z"
              ></path>
              <path
                d="M17.3,14.227c-0.2,0-0.389-0.078-0.53-0.22c-1.272-1.272-2.968-1.973-4.773-1.973s-3.5,0.701-4.773,1.973
        c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22c-0.142-0.142-0.22-0.33-0.22-0.53s0.078-0.389,0.22-0.53
        c1.556-1.556,3.627-2.412,5.833-2.412c2.206,0,4.278,0.857,5.833,2.412c0.142,0.142,0.22,0.33,0.22,0.53s-0.078,0.389-0.22,0.53
        S17.5,14.227,17.3,14.227z"
              ></path>
              <path
                d="M20.485,11.045c-0.2,0-0.389-0.078-0.53-0.22C17.833,8.705,15.008,7.537,12,7.537c-3.008,0-5.833,1.168-7.955,3.289
        c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22c-0.142-0.142-0.22-0.33-0.22-0.53c0-0.2,0.078-0.389,0.22-0.53
        C5.389,7.361,8.591,6.037,12,6.037c3.409,0,6.611,1.324,9.015,3.728c0.142,0.141,0.22,0.33,0.22,0.53c0,0.2-0.078,0.389-0.22,0.53
        C20.874,10.967,20.685,11.045,20.485,11.045z"
              ></path>
              <path
                d="M0.75,7.462c-0.207,0-0.407-0.087-0.549-0.239c-0.282-0.302-0.265-0.778,0.037-1.06C3.429,3.19,7.606,1.552,12,1.552
        c4.395,0,8.571,1.638,11.761,4.611c0.302,0.282,0.319,0.757,0.038,1.06c-0.142,0.152-0.342,0.239-0.549,0.239
        c-0.19,0-0.372-0.072-0.511-0.201C19.827,4.546,16.013,3.051,12,3.051S4.173,4.546,1.261,7.26C1.122,7.39,0.94,7.462,0.75,7.462z"
              ></path>
            </g>
          </svg>
        </i>
        Wi-Fi
      </div>
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: 24,
              width: 24,
              fill: "currentcolor",
            }}
          >
            <path d="M16 2a5 5 0 0 1 1.66 9.72 1 1 0 0 0-.65.81l-.01.13v.81l13.23 9.05a3 3 0 0 1 1.3 2.28v.2a3 3 0 0 1-3 3H3.47a3 3 0 0 1-1.69-5.48L15 13.47v-.81a3 3 0 0 1 1.82-2.76l.17-.07a3 3 0 1 0-3.99-3V7h-2a5 5 0 0 1 5-5zm0 13.21L2.9 24.17A1 1 0 0 0 3.46 26h25.07a1 1 0 0 0 .57-1.82z"></path>
          </svg>
        </i>
        Elbise Askıları
      </div>
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: 24,
              width: 24,
              fill: "currentcolor",
            }}
          >
            <path d="M29 15v16h-2v-6h-6v6h-2v-6.15a2 2 0 0 1 1.84-1.84L21 23h6v-8zM5 15v8h6a2 2 0 0 1 2 1.85V31h-2v-6H5v6H3V15zM16 1a15 15 0 0 1 13.56 8.57 1 1 0 0 1-.8 1.42l-.1.01H17v8h8v2h-8v10h-2V21H7v-2h8v-8H3.35a1 1 0 0 1-.95-1.32l.04-.1A15 15 0 0 1 16 1zm0 2A13 13 0 0 0 5.4 8.47l-.2.28-.16.25h21.92l-.17-.25a13 13 0 0 0-10.1-5.73L16.34 3z"></path>
          </svg>
        </i>
        Bahçe Mobilyaları
      </div>
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: 24,
              width: 24,
              fill: "currentcolor",
            }}
          >
            <path d="M13 2h2c0 2.06-.48 3.34-1.77 5.42l-.75 1.19C11.6 10 11.2 10.9 11.06 12H9.04c.1-1.07.38-1.97.9-3H6a10 10 0 0 0 20 .28V9h-3.77a7.44 7.44 0 0 0-1.17 3h-2.02c.15-1.61.71-2.84 1.91-4.73l.57-.88c1.11-1.79 1.47-2.78 1.47-4.4h2c0 1.93-.4 3.17-1.5 5L28 7v2c0 .68-.06 1.35-.17 2H30v2h-2.68a12.04 12.04 0 0 1-5.9 6.7l4.5 9.89-1.83.82-2-4.41H17v4h-2v-4H9.92L7.9 30.41l-1.82-.82 4.49-9.88A12.04 12.04 0 0 1 4.68 13H2v-2h2.17A12.06 12.06 0 0 1 4 9.3V7h7.13l.39-.6c1.11-1.8 1.47-2.8 1.47-4.4zm-.57 18.46L10.83 24H15v-3.04a11.95 11.95 0 0 1-2.57-.5zm4.57.5V24h4.17l-1.6-3.54c-.69.21-1.4.37-2.13.45zM18 2h2c0 2.06-.48 3.35-1.77 5.42l-.75 1.19C16.6 10 16.2 10.9 16.06 12h-2.02c.15-1.62.71-2.84 1.91-4.74l.57-.88C17.63 4.6 17.99 3.61 17.99 2z"></path>
          </svg>
        </i>
        Mangal
      </div>
      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: 24,
              width: 24,
              fill: "currentcolor",
            }}
          >
            <path d="M16 0a5 5 0 0 1 5 4.78v12.98l.26.21a7.98 7.98 0 0 1 2.72 5.43l.02.3v.3a8 8 0 1 1-13.25-6.04l.25-.2V5A5 5 0 0 1 15.56.02l.22-.01zm0 2a3 3 0 0 0-3 2.82V18.78l-.43.3a6 6 0 1 0 7.06.15l-.2-.16-.43-.3V11h-4V9h4V7h-4V5h4a3 3 0 0 0-3-3zm1 11v7.13A4 4 0 0 1 16 28a4 4 0 0 1-1-7.87V13zm-1 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"></path>
          </svg>
        </i>
        Yerden Isıtma
      </div>

      <div className="facility-item" style={{ height: 50 }}>
        <i>
          <svg
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: 24,
              width: 24,
              fill: "currentcolor",
            }}
          >
            <path d="M16 1a15 15 0 1 1 0 30 15 15 0 0 1 0-30zm0 2a13 13 0 1 0 0 26 13 13 0 0 0 0-26zm-4.9 14a5 5 0 0 0 3.9 3.9v2.03A7 7 0 0 1 9.07 17zm9.8 0h2.03A7 7 0 0 1 17 22.93V20.9a5 5 0 0 0 3.9-3.9zM16 13a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm1-5.93A7 7 0 0 1 22.93 15H20.9a5 5 0 0 0-3.9-3.9zm-2 0v2.03a5 5 0 0 0-3.9 3.9H9.07A7 7 0 0 1 15 9.07zM23 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
          </svg>
        </i>
        Duman Dedektörü
      </div>
    </div>
  );
};

export default FacilityList;
