import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Bungalows from "./components/Bungalows";
import Services from "./components/Services";
import Campaigns from "./components/Campaigns";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import "./App.css"; // Ensure your App.css is imported
import WhatsAppButton from "./components/WhatsAppButton";
import ReservationButton from "./components/ReservationButton";
import { hotjar } from "react-hotjar";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    hotjar.initialize({ id: 5140862, sv: 6 });
  }, []);

  return (
    <div className="App">
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <HeroSection isMenuOpen={isMenuOpen} />
      <Bungalows />
      <Gallery />
      <Services />
      <Campaigns />
      <Footer />
      {!isMenuOpen && <WhatsAppButton />}
      {!isMenuOpen && <ReservationButton />}
    </div>
  );
}

export default App;
